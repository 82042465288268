h1 {
    font-size: 40px;
    line-height: 48px;
    margin: 0;
    font-weight: 600;
}

h2 {
    font-size: 14px;
    line-height: 20px;
    margin: 15px 0px;
    font-weight: 600;
}

p {
    margin-top: 0;
}

.hero-slides {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.hero-slide {
    opacity: 0;
    display: none;
    transition: opacity 1s ease;
    z-index: 1;
}

.hero-slide--active {
    opacity: 1 !important;
    display: block;
    z-index: 2 !important;
}

.hero-slide-media {
    width: 100%;
    height: 100vh;
    object-fit: cover;
}

.hero-slide-content {
    position: absolute;
    top: 65%;
    left: 3%;
    /* transform: translate(-50%, -50%); */
    color: #fff;
    text-align: left;
    z-index: 2;
    max-width: 50vw;
}

.hero-tab-list {
    position: absolute;
    bottom: 36%;
    left: 3.2%;
    /* transform: translateX(-50%); */
    display: flex;
    gap: 10px;
    z-index: 2;
}

.tab {
    border-radius: 50%;
    border: 0;
    height: 12px;
    width: 12px;
    padding: 0;
    opacity: 0.7
}

.tab--active {
    background-color: white;
    opacity: 1;
}

.cta-primary, .cta-secondary {
    width: 200px;
    height: 40px;
    border-radius: 4px;
    border-width: 0;
    font-size: 18px;
    font-weight: 500;
    z-index: 2;
    margin-top: 5px;
}

.cta-primary {
    background-color: white;
    margin-right: 20px;
    color: black;
}

.cta-secondary {
    background-color: rgb(25, 25, 25);
    color: white;
}

.modal-link {
    border: none;
    background-color: transparent;
    color: white;
    text-decoration: underline;
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    padding: 0;
    cursor: pointer;
}

.blur {
    filter: blur(4px);
}

@media (max-width: 600px) {
    .hero-slide-content {
        max-width: 95vw;
        top: 45%;
    }

    .hero-tab-list {
        bottom: 56%;
    }

    .hero-slide-content > h1 {
        font-size: 30px;
    }
}