#nav {
    display: flex;
    position: absolute;
    z-index: 4;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    height: 55px;
    background-color: transparent;
}

#nav.menu-open {
    background-color: white;
    transition: background-color 0s ease 0.5s;
}

.logo {
    width: 120px;
    margin: 0 0 0 40px;
    z-index: 4;
}

#menu-items-middle, #menu-items-end {
    display: flex;
    justify-content: space-around;
    list-style-type: none;
    align-items: center;
}

#menu-items-middle {
    padding: 0;
}

#menu-items-end {
    margin: 10px 45px 10px 0;
    padding: 0;
}

#menu-items-middle > li > button {
    background-color: transparent;
    border: none;
    color: white;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: .02em;
    margin: 0 10px;
    padding: 7px;
    transition: color .33s ease,background-color .33s ease;
}

.submenu-open > li > button {
    color: black !important;
}

.logo.submenu-open {
    filter: brightness(1) invert(1);
}

#menu-items-end > li > a {
    padding: 8px;
    border: 0px;
    border-radius: 4px;
    transition: color .33s ease,background-color .33s ease;
}

#menu-items-end > li > a:hover {
    background-color: rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(16px);
}

.active {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    backdrop-filter: blur(16px);
    transition: background-color 0.33s ease;
}

#mobile-menu {
    display: none;
}

@media (max-width: 1200px) {
    #menu-items-middle, #menu-items-end {
        display: none;
    }

    #mobile-menu {
        display: block;
        background-color: rgba(255, 255, 255, 0.3);
        border: none;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 500;
        color: white;
        padding: 7px 18px;
        margin-right: 20px;
        cursor: pointer;
        transition: background-color 0.33s ease;
        z-index: 1;
    }

    #mobile-menu:hover {
        background-color: rgba(255, 255, 255, 0.5);
    }

    #nav {
        background-color: transparent !important;
    }
}