.mobile-modal.visible {
    opacity: 1;
    transition: opacity 0.5s cubic-bezier(0.5, 0, 0, 0.75);
    z-index: 4;
}

.mobile-modal {
    position: fixed;
    top: 0%;
    width: 100vw;
    height: 100vh;
    background-color: white;
    z-index: 0;
    overflow-y: auto;
    padding: 0;
    opacity: 0;
    transition: opacity 0.5s cubic-bezier(0.5, 0, 0, 0.75);
    display: flex;
    flex-direction: column;
    gap: 16px;
    z-index: -1;
}

#mobile-close-container {
    width: calc(100% - 32px);
    display: flex;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
}

.modal-close.mobile {
    padding: 4.5px 7.5px;
    border-radius: 4px;
}

.modal-close.mobile:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.mobile-menu-back.mobile {
    padding: 0px 7.5px;
    border-radius: 4px;
    background-color: white;
    border: none;
    height: 32px;
    width: 32px;
    visibility: hidden;
}

.mobile-menu-back.mobile:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.mobile-menu-back.mobile.visible {
    visibility: visible;
}

.mobile-menu-button, .side-items-menu.mobile > ul > li, .section-links.mobile > li {
    font-size: 17px;
    font-weight: 500;
    text-transform: capitalize;
    text-align: left;
    border: none;
    border-radius: 4px;
    padding: 16px 8px;
    background-color: white;
    cursor: pointer;
    transition: background-color 0.33s ease;
    margin: 0 16px;
    color: black;
}

.mobile-menu-button:hover, .side-items-menu.mobile > ul > li:hover, .section-links.mobile > li:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.mobile-menu-title {
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
}

.mobile-main-items {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-evenly;
    border-bottom: 1px solid rgb(226, 227, 227);
    padding-bottom: 10px;
}

.side-items-menu.mobile {
    border: none;
    width: 100%;
}

.side-items-menu.mobile > ul {
    padding: 0;
}

.side-items-menu.mobile > ul > li > a {
    font-size: 17px;
    font-weight: 500;
}

.sections-menu.mobile {
    flex-direction: column;
    width: 100%;
}

.section-links.mobile {
    font-size: 17px;
    font-weight: 500;
    margin: 0;
}

.mobile-menu-button.language {
    display: flex;
    color: black;
    align-items: center;
    gap: 15px;
    border: none;
}

.mobile-region-language {
    display: flex;
    flex-direction: column;
}

.mobile-region-language > span {
    color: rgb(92, 94, 98);
    font-size: 14px;
}

.mobile-menu-button > div {
    display: flex;
    justify-content: space-between;
    color: black;
}

@media (min-width: 900px) {
    .deeper-menu {
        display: none;
    }
}

@media (max-width: 450px) {
    .mobile-main-items {
        flex-direction: column;
        align-items: center;
    }

    .product {
        width: 90%;
        flex-direction: row;
        justify-content: center;
        gap: 20px;
    }

    .product-content {
        text-align: left;
        justify-content: flex-start;
        width: 160px;
    }

    .product-content-links {
        text-align: left;
        justify-content: flex-start;
        padding: 0 5px;
        gap: 20px;
    }

    .product-image {
        width: 160px;
    }
}